import tw, { css, styled, theme } from 'twin.macro';
import { Body2, Heading6 } from '@components/Texts/Texts';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #202025;
  ${tw`flex flex-col items-start justify-end p-3 rounded-lg`};
`;

export const Title = styled(Heading6)`
  ${tw`text-white mt-3`}
`;

export const Text = styled(Body2)`
  ${tw`text-light-low mt-1`}
  ${tw`sm:hidden`};
  ${tw`md:hidden`};
  ${css`
    @media (min-width: ${theme`screens.lg.min`}) and (max-width: 1190px) {
      ${tw`hidden`};
    }
  `}
`;
