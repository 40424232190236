import { CelebrityImageCardProps } from './CelebrityImageCard.types';
import { CelebrityImage, Container, Gradient, Text } from './CelebrityImageCard.styles';

const CelebrityImageCard = ({ img, name }: CelebrityImageCardProps): JSX.Element => {
  return (
    <Container>
      <Text>{name}</Text>
      <Gradient />
      <CelebrityImage src={img} alt={name} layout='fill' objectFit='cover' />
    </Container>
  );
};

export default CelebrityImageCard;
