import MoneyBag from '@assets/money_bag';

const AmrDiab = {
  type: 'CelebrityImage',
  name: 'AmrDiab',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/09_Jc51ywTcq.jpg',
};
const HaifaWehbe = {
  type: 'CelebrityImage',
  name: 'HaifaWehbe',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/Haifa_LLaDhRbu2.jpg',
};
const Youssra = {
  type: 'CelebrityImage',
  name: 'Youssra',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/10_47whZRChi.jpg',
};
const AhmedElSakka = {
  type: 'CelebrityImage',
  name: 'AhmedElSakka',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/ahmed_el_sakka__iJP-MOSV.jpg',
};
const MohamedHenedy = {
  type: 'CelebrityImage',
  name: 'MohamedHenedy',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/02_6fGMldZfR.jpg',
};
const KarimAbdelAziz = {
  type: 'CelebrityImage',
  name: 'KarimAbdelAziz',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/03_3TtTsHIrj.jpg',
};
const Chico = {
  type: 'CelebrityImage',
  name: 'Chico',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/Chico1_7esSXpzuBt2.jpg',
};
const GhadaAdel = {
  type: 'CelebrityImage',
  name: 'GhadaAdel',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/05_0BQQi3ak-.jpg',
};
const AmrYoussef = {
  type: 'CelebrityImage',
  name: 'AmrYoussef',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/Amr_Youssef_-_NEW_ZBhNcbOtJ.jpg',
};
const Dorra = {
  type: 'CelebrityImage',
  name: 'Dorra',
  imageURL: 'https://ik.imagekit.io/yela/Production/Marquee/06_09yur8boF5C.jpg',
};
const Discount = {
  type: 'Text',
  title: 'Get discounted shoutouts',
  text: 'We’re giving away discounts for returning users',
  icon: <MoneyBag />,
};

export const I18N_COMMON_CONTEXT = 'common';
export const celebrityReelVerticalMoveUp = [false, true, false];
export const celebrityReelVerticalRepeat = 2;
export const celebrityReelVerticalData = [
  [AmrDiab, AhmedElSakka, AmrYoussef, AmrDiab, AhmedElSakka, AmrYoussef],
  [Youssra, Chico, GhadaAdel, Youssra, Chico, GhadaAdel],
  [Dorra, HaifaWehbe, MohamedHenedy, Dorra, HaifaWehbe, MohamedHenedy],
];

export const celebrityReelHorizontalData = [AmrDiab, HaifaWehbe, Youssra, AhmedElSakka, MohamedHenedy, Chico, GhadaAdel, AmrYoussef, Dorra];
