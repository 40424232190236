import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

// Vertical Styling
export const VerticalReelWrapper = styled.div`
  height: 100vh;
  width: 55%;
  ${tw`end-3 fixed overflow-hidden`};
`;

export const Mover = styled.div`
  ${tw`space-y-4 w-full`};
  will-change: transform;
  transform: translateY(0) translateZ(0);
`;

const move = keyframes`
  from { transform: translateY(0%); }
  to { transform: translateY(calc(-50% - 16px)); }
`;

export const RepeatContainer = styled.div`
  ${tw`flex flex-row space-s-3 w-full`}
  animation: ${move} 15s linear infinite;
`;

export const Column = styled.div<{ moveUp?: boolean }>`
  transform: ${props => props.moveUp && 'translate(0px, -9%)'};
  ${tw`flex flex-col space-y-4`}
`;

export const CardContainerVertical = styled.div`
  height: 22.2vw;
  width: 16.65vw;
`;

// Horizontal Styling
export const HorizontalContainer = styled.div`
  height: 100%;
  direction: ltr;
  ${tw`py-4`}
`;

export const CardContainerHorizontal = styled.div`
  height: 240px;
  width: 180px;
  margin-right: 12px;
  margin-left: 12px;
  [dir='rtl'] & {
    direction: rtl;
  }
`;
