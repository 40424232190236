import { getImageSrc } from '@common/image-helper';
import CelebrityImageCard from '@features/auth/CelebrityImageCard/CelebrityImageCard';
import TextCard from '@features/auth/TextCard/TextCard';
import useWindowWidth from '@hooks/useWindowWidth';
import { useYela } from '@hooks/useYela';
import useTranslation from 'next-translate/useTranslation';
import FastMarquee from 'react-fast-marquee';
import {
  celebrityReelHorizontalData,
  celebrityReelVerticalData,
  celebrityReelVerticalMoveUp,
  celebrityReelVerticalRepeat,
  I18N_COMMON_CONTEXT,
} from './Marquee.constants';
import {
  CardContainerHorizontal,
  CardContainerVertical,
  Column,
  HorizontalContainer,
  Mover,
  RepeatContainer,
  VerticalReelWrapper,
} from './Marquee.styles';
import { Card, MarqueeInterface } from './Marquee.types';

const Marquee = ({ type }: MarqueeInterface): JSX.Element => {
  const { language } = useYela();
  const windowWidth = useWindowWidth();
  const isLgXl = windowWidth > 1023;
  const { t } = useTranslation(I18N_COMMON_CONTEXT);

  const showVertical = type == 'vertical' && isLgXl;
  const moveUp: boolean[] = celebrityReelVerticalMoveUp;
  const repeat: number = celebrityReelVerticalRepeat;
  const verticalData: Card[][] = celebrityReelVerticalData;

  const showHorizontal = type == 'horizontal' && !isLgXl;
  const horizontalData: Card[] = celebrityReelHorizontalData;
  const cardContainerHorizontal = showHorizontal && (
    <>
      {horizontalData.map((item, index) => {
        return (
          <CardContainerHorizontal key={index}>
            {item.type === 'Text' ? (
              <TextCard title={item.title || ''} text={item.text || ''} icon={item.icon || <span />} />
            ) : (
              <CelebrityImageCard
                img={getImageSrc({
                  imageSrc: item.imageURL + '',
                  height: 240,
                  width: 180,
                })}
                name={t(item.name + '') || ''}
              />
            )}
          </CardContainerHorizontal>
        );
      })}
    </>
  );

  return (
    <>
      {showHorizontal && (
        <HorizontalContainer>
          <FastMarquee gradient={false} speed={50} direction={language == 'en' ? 'left' : 'right'}>
            {cardContainerHorizontal}
          </FastMarquee>
        </HorizontalContainer>
      )}
      {showVertical && (
        <VerticalReelWrapper>
          <Mover>
            {new Array(repeat).fill(0).map((e, index) => (
              <RepeatContainer key={index}>
                {verticalData.map((column, index) => {
                  return (
                    <Column key={index} moveUp={moveUp[index]}>
                      {column.map((item, index) => {
                        return (
                          <CardContainerVertical key={index}>
                            {item.type === 'Text' ? (
                              <TextCard title={item.title || ''} text={item.text || ''} icon={item.icon || <span />} />
                            ) : (
                              <CelebrityImageCard
                                img={getImageSrc({
                                  imageSrc: item.imageURL + '',
                                  height: 320,
                                  width: 240,
                                })}
                                name={t(item.name + '') || ''}
                              />
                            )}
                          </CardContainerVertical>
                        );
                      })}
                    </Column>
                  );
                })}
              </RepeatContainer>
            ))}
          </Mover>
        </VerticalReelWrapper>
      )}
    </>
  );
};

export default Marquee;
