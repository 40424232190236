import { TextCardProps } from './TextCard.types';
import { Container, Text, Title } from './TextCard.styles';

const TextCard = ({ icon, title, text }: TextCardProps): JSX.Element => {
  return (
    <Container>
      {icon}
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Container>
  );
};

export default TextCard;
