import Image from 'next/image';
import tw, { styled } from 'twin.macro';
import { Heading6 } from '@components/Texts/Texts';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  ${tw`relative flex items-end justify-start p-3 rounded-lg`};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.11%, rgba(0, 0, 0, 0.75) 100%);
`;

export const Gradient = styled.div`
  z-index: 1;
  ${tw`absolute inset-0 rounded-lg`};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.11%, rgba(0, 0, 0, 0.75) 100%);
`;

export const CelebrityImage = styled(Image)`
  ${tw`absolute inset-0 w-full h-full object-cover rounded-lg z-0`};
`;

export const Text = styled(Heading6)`
  ${tw`text-white`}
  z-index: 2;
`;
